import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const user = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    /* const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection type={'companyLogo'} />
                </Box>
            </Box>
        ),
        []
    ); */

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '5px',
                    paddingRight: '5px'
                }}
            >
                <MenuList />
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    const companyLogo = () => {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '25px',
                paddingRight: '95px',
                paddingTop: '15px',
                paddingBottom: '10px',
                borderBottom: '1.5px solid rgba(194, 199, 208, 0.25)'
            }}>
                <LogoSection type={'companyLogo'} />
            </Box>
        )
    }

    const userFullName = () => {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                paddingLeft: '25px',
                paddingTop: '15px',
                paddingBottom: '15px',
                borderBottom: '1.5px solid rgba(194, 199, 208, 0.25)'
            }}>
                <Typography sx={{ fontSize: '14px', color: '#c2c7d0', fontWeight: '500' }} color='#c2c7d0'>{user.name.charAt(0).toUpperCase() + user.name.slice(1) + ' ' + user.surname.charAt(0).toUpperCase() + user.surname.slice(1)}</Typography>
            </Box>
        )
    }

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: '#343a40',
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        /* če želiš tako, da je pasica čez celotno širino odkomentiraj */
                        /* [theme.breakpoints.up('md')]: {
                            top: '88px'
                        } */
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {companyLogo()}
                {userFullName()}
                {drawerOpen && drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
