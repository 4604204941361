// assets
import {
    IconWorld,
    IconDeviceDesktopAnalytics,
    IconTable,
    IconNotification,
    IconDeviceMobileMessage
} from '@tabler/icons';

// constant
const icons = {
    IconWorld,
    IconDeviceDesktopAnalytics,
    IconTable,
    IconNotification,
    IconDeviceMobileMessage
};

// ==============================|| MENU ITEMS ||============================== //

const telemetries = {
    id: 'telemetries',
    type: 'group',
    children: [
        {
            id: 'telemetries',
            title: 'Telemetrije',
            type: 'collapse',
            icon: icons.IconDeviceDesktopAnalytics,
            permission: [1, 2, 3, 4, 5, 6],
            children: [
                {
                    id: 'table_view',
                    title: 'Vpogled v tabeli',
                    type: 'item',
                    url: '/table_view',
                    icon: icons.IconTable,
                    breadcrumbs: false,
                    permission: [1, 2, 3, 4, 5, 6]
                },
                {
                    id: 'telemetry_map',
                    title: 'Vpogled na mapi',
                    type: 'item',
                    url: '/telemetry_map',
                    icon: icons.IconWorld,
                    breadcrumbs: false,
                    permission: [1, 2, 3, 4, 5, 6]
                },
                {
                    id: 'telemetry_notifications',
                    title: 'Nastavitve obvestil',
                    type: 'item',
                    url: '/telemetry_notifications',
                    icon: icons.IconDeviceMobileMessage,
                    breadcrumbs: false,
                    permission: [1, 2, 3, 4, 5, 6]
                },
            ]
        }
    ]
};

export default telemetries;
