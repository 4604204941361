import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //
const LogoSection = ({type}) => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <Logo type={type}/>
    </Link>
);

LogoSection.propTypes = {
    type: PropTypes.string
};

export default LogoSection;
