import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
    const { isLoggedIn, logout } = useAuth();
    const accessToken = localStorage.getItem("accessToken")
    const verifyToken = () => {
        if (!accessToken || accessToken === '') {
            return false;
        }
        const decoded = jwtDecode(accessToken);
        /**
         * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
         */
        return decoded.exp > Date.now() / 1000;
    };
    const navigate = useNavigate();

    useEffect(() => {
        if (!verifyToken()) {
            try {
                logout();
            } catch (err) {
                console.error(err);
            }
        }
    }, [navigate])

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
