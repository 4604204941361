import administration from "./administration";
import telemetries from "./telemetries";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [telemetries, administration]
};

export default menuItems;
