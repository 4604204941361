// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconFile,
    IconShare,
    IconTrafficLights,
    IconWorld,
    IconPhone,
    IconTransferIn,
    IconTruck,
    IconThermometer,
    IconTool,
    IconBuildingSkyscraper,
    IconSocial,
    IconSatellite,
    IconPlanet,
    IconPuzzle2,
    IconRuler2,
    IconDevices2,
    IconNotification,
    IconLicense,
    IconNotes,
    IconFiles,
    IconLock,
    IconForbid
} from '@tabler/icons';


// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconFile,
    IconShare,
    IconTrafficLights,
    IconWorld,
    IconPhone,
    IconTransferIn,
    IconTruck,
    IconThermometer,
    IconTool,
    IconBuildingSkyscraper,
    IconSocial,
    IconSatellite,
    IconPlanet,
    IconPuzzle2,
    IconRuler2,
    IconDevices2,
    IconNotification,
    IconLicense,
    IconNotes,
    IconFiles,
    IconForbid,
    IconLock
};

// ==============================|| MENU ITEMS ||============================== //

const administration = {
    id: 'administration',
    type: 'group',
    children: [
        {
            id: 'administration',
            title: 'Administracija',
            type: 'collapse',
            icon: icons.IconTool,
            permission: [1, 2, 3, 4, 6],
            children: [
                {
                    id: 'users',
                    title: 'Uporabniki',
                    type: 'item',
                    url: '/users',
                    icon: icons.IconUsers,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'companies',
                    title: 'Podjetja',
                    type: 'item',
                    url: '/companies',
                    icon: icons.IconBuildingSkyscraper,
                    breadcrumbs: false,
                    permission: [1, 2, 3]
                },
                {
                    id: 'distributors',
                    title: 'Distributerji',
                    type: 'item',
                    url: '/distributors',
                    icon: icons.IconSocial,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'system_types',
                    title: 'Sistemski tipi',
                    type: 'item',
                    url: '/system_types',
                    icon: icons.IconSatellite,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'countries',
                    title: 'Države',
                    type: 'item',
                    url: '/countries',
                    icon: icons.IconPlanet,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'regions',
                    title: 'Regije',
                    type: 'item',
                    url: '/regions',
                    icon: icons.IconPuzzle2,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'gsm_modules',
                    title: 'GSM moduli',
                    type: 'item',
                    url: '/gsm_modules',
                    icon: icons.IconPhone,
                    breadcrumbs: false,
                    permission: [1]
                },
                {
                    id: 'sensor_types',
                    title: 'Tipi senzorjev',
                    type: 'item',
                    url: '/sensor_types',
                    icon: icons.IconRuler2,
                    breadcrumbs: false,
                    permission: [1]
                },
                {
                    id: 'telemetry_systems',
                    title: 'Telemetrija',
                    type: 'item',
                    url: '/telemetry_systems',
                    icon: icons.IconDevices2,
                    breadcrumbs: false,
                    permission: [1, 2, 3, 4, 6]
                },
                ,
                {
                    id: 'files',
                    title: 'Datoteke',
                    type: 'item',
                    url: '/files',
                    icon: icons.IconFiles,
                    breadcrumbs: false,
                    permission: [1, 2, 3, 6]
                },
                {
                    id: 'permissions',
                    title: 'Dovoljenja',
                    type: 'item',
                    url: '/permissions',
                    icon: icons.IconLock,
                    breadcrumbs: false,
                    permission: [1, 2]
                },
                {
                    id: 'log_statuses',
                    title: 'Log statusov',
                    type: 'item',
                    url: '/log_statuses',
                    icon: icons.IconLicense,
                    breadcrumbs: false,
                    permission: [1, 2, 3]
                },
                {
                    id: 'logs',
                    title: 'Log',
                    type: 'item',
                    url: '/logs',
                    icon: icons.IconNotes,
                    breadcrumbs: false,
                    permission: [1, 2]
                }
            ]
        }
    ]
}

export default administration;
