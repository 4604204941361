// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    company: {},
    distributor: {},
    email: '',
    id: null,
    name: '',
    surname: '',
    userName: '',
    userRole: {}
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        activeUser(state, action) {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.userRole = action.payload.userRole;
            state.company = action.payload.company;
            state.distributor = action.payload.distributor;
            state.email = action.payload.email;
            state.userName = action.payload.userName;
        }
    }
});

// Reducer
export default user.reducer;

export const { activeUser } = user.actions;
