import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Box, IconButton, Button } from '@mui/material';

import { IconCirclePlus } from '@tabler/icons';
import { useSelector } from 'react-redux';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            headerAction,
            export1,
            exportY = false,
            restrictedRoles,
            buttonLabel,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        const user = useSelector((state) => state.user)
        const [restricted, setRestricted] = useState(true)

        useEffect(() => {
            if (restrictedRoles === undefined) {
                setRestricted(false)
            }

            else if (restrictedRoles !== undefined) {
                if (restrictedRoles.includes(user.userRole.id)) {
                    setRestricted(false)
                }
            }
        }, [restrictedRoles])// eslint-disable-line react-hooks/exhaustive-deps

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                            (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && headerAction && !exportY && <CardHeader
                    sx={headerSX}
                    title={<Box
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{title}</span>
                        {(!restricted && !buttonLabel) && <IconButton onClick={headerAction}>
                            <IconCirclePlus stroke={1.5} size="2.5rem" color='#005dab' />
                        </IconButton>}
                        {
                            buttonLabel && <Button sx={{ backgroundColor: '#005dab' }} variant='contained' onClick={headerAction}>{buttonLabel}</Button>
                        }
                    </Box>}
                    action={secondary}
                />}
                {!darkTitle && title && !headerAction && exportY && <CardHeader
                    sx={headerSX}
                    title={<Box
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{title}</span>
                        {(!restricted && !buttonLabel) && <Button variant='contained' onClick={export1}>Izvozi</Button>}
                    </Box>}
                    action={secondary}
                />}
                {!darkTitle && title && !headerAction && !exportY && (
                    <CardHeader sx={headerSX} title={title} action={secondary} />
                )}
                {darkTitle && title && !exportY && (
                    <CardHeader sx={headerSX} title={<div><Typography variant="h3">{title}</Typography><span></span></div>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    headerAction: PropTypes.func,
    restrictedRoles: PropTypes.array,
    buttonLabel: PropTypes.string
};

export default MainCard;
