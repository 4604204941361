import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
/* import { GetUserStart } from 'store/actions'; */

import { activeUser } from 'store/slices/user';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { useDispatch } from 'store';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    accesssToken: null
};

const initialStateUser = {
    company: {},
    distributor: {},
    email: '',
    id: null,
    name: '',
    surname: '',
    userName: '',
    userRole: {}
};

const verifyToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);



export const JWTProvider = ({ children }) => {
    const [state, test] = useReducer(accountReducer, initialState);
    const dispatch = useDispatch();
    const [jwtToken] = useState(localStorage.getItem('accessToken'));

    useEffect(() => {
        if (jwtToken) {
            const decoded2 = jwt_decode(window.localStorage.getItem('accessToken'));
            axios.get(process.env.REACT_APP_API_URL + '/user/' + decoded2.sub, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken') } })
                .then(res => {
                    const userData = res.data;
                    dispatch(activeUser(userData))
                })
        }
    }, [jwtToken]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setInterval(() => {
            const token = window.localStorage.getItem('accessToken')
            if (token && verifyToken(token)) {
                return
            }
            else {
                test({
                    type: LOGOUT,
                    payload: {
                        isLoggedIn: false,
                        accessToken: null
                    }
                })
            }
        }, 60000)
        const init = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken && verifyToken(accessToken)) {
                    setSession(accessToken);
                    test({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            accessToken: accessToken
                        }
                    });
                } else {
                    test({
                        type: LOGOUT,
                        payload: {
                            isLoggedIn: false,
                            accessToken: null
                        }
                    });
                }
            } catch (err) {
                test({
                    type: LOGOUT,
                    payload: {
                        isLoggedIn: false,
                        accessToken: null
                    }
                });
            }
        };

        init();
    }, []);

    const login = async (username, password) => {
        const response = axios.post(process.env.REACT_APP_API_URL + '/login', { username, password });
        const accessToken = (await response).data;
        setSession(accessToken.token);
        const decoded2 = jwt_decode(accessToken.token);
        const responseGetUser = axios.get(process.env.REACT_APP_API_URL + '/user/' + decoded2.sub);
        const userData = (await responseGetUser).data;
        dispatch(activeUser(userData))
        test({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                accessToken: accessToken.token
            }
        });
    };

    const register = async (username, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            username,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        test({ type: LOGOUT });
        dispatch(activeUser(initialStateUser))
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => { };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
