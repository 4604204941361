import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

//page routing

const Users = Loadable(lazy(() => import('views/users')));

const Companies = Loadable(lazy(() => import('views/companies')));

const Files = Loadable(lazy(() => import('views/files')));

const Distributors = Loadable(lazy(() => import('views/distributors')));

const SystemTypes = Loadable(lazy(() => import('views/system_types')));

const Countries = Loadable(lazy(() => import('views/countries')));

const Regions = Loadable(lazy(() => import('views/regions')));

const GsmModules = Loadable(lazy(() => import('views/gsm_modules')));

const SensorTypes = Loadable(lazy(() => import('views/sensor_types')));

const Customers = Loadable(lazy(() => import('views/customers')));

const TelemetrySystems = Loadable(lazy(() => import('views/telemetry_systems')));

const TelemetryMap = Loadable(lazy(() => import('views/telemetry_map')));

const TelemetryPlaning = Loadable(lazy(() => import('views/planing')));

const Settings = Loadable(lazy(() => import('views/settings')));

const TelemetryView = Loadable(lazy(() => import('views/telemetry_view')));

const TelemetryHistoryGraph = Loadable(lazy(() => import('views/telemetry_history_graph')));

const TelemetryHistoryTable = Loadable(lazy(() => import('views/telemetry_history_table')));

const TelemetryAccess = Loadable(lazy(() => import('views/telemetry_systems/access')));

const TelemetryNotifications = Loadable(lazy(() => import('views/telemetry_notifications')));

const Notifications = Loadable(lazy(() => import('views/notifications')));

const Logs = Loadable(lazy(() => import('views/logs')));

const LogStatuses = Loadable(lazy(() => import('views/log_statuses')))

const Permissions = Loadable(lazy(() => import('views/permissions')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <TelemetryView />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/companies',
            element: <Companies />
        },
        {
            path: '/files',
            element: <Files />
        },
        {
            path: '/distributors',
            element: <Distributors />
        },
        {
            path: '/system_types',
            element: <SystemTypes />
        },
        {
            path: '/countries',
            element: <Countries />
        },
        {
            path: '/regions',
            element: <Regions />
        },
        {
            path: '/gsm_modules',
            element: <GsmModules />
        },
        {
            path: '/sensor_types',
            element: <SensorTypes />
        },
        {
            path: '/customers',
            element: <Customers />
        },
        {
            path: '/telemetry_systems',
            element: <TelemetrySystems />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/telemetry_map',
            element: <TelemetryMap />
        },
        {
            path: '/telemetry_planing',
            element: <TelemetryPlaning />
        },
        {
            path: '/table_view',
            element: <TelemetryView />
        },
        {
            path: '/telemetry_history_G/:uid',
            element: <TelemetryHistoryGraph />
        },
        {
            path: '/telemetry_history_T/:uid',
            element: <TelemetryHistoryTable />
        }
        ,
        {
            path: '/telemetry_systems/:uid',
            element: <TelemetryAccess />
        }
        ,
        {
            path: '/telemetry_notifications',
            element: <TelemetryNotifications />
        }
        ,
        {
            path: '/notifications',
            element: <Notifications />
        }
        ,
        {
            path: '/logs',
            element: <Logs />
        },
        {
            path: '/log_statuses',
            element: <LogStatuses />
        },
        {
            path: '/permissions',
            element: <Permissions />
        }
    ]
};

export default MainRoutes;
